import React from 'react'
import { FontAwesomeIcon } from './General'

// Link Components
// todo - pull out into its own file

// todo - create separate confirmation deletion dialog link helper
// so I'm not repeating it everywhere

export const GoalsShowLink = ({ goal }) => {
    return (
      <a href={`/goals/${goal.id}`}>{goal.title}</a>
    );
  };

export const GoalsEditLink = ({ goal, icon }) => {
    return (
      <a href={`/goals/${goal.id}/edit`} className="edit">
        { 
          icon ? <FontAwesomeIcon icon={icon} /> : 'Edit'
        }
      </a>
    );
  };

export const GoalsDeleteLink = ({ goal, icon }) => {
    return (
      <DeletionLink href={`/goals/${goal.id}`} body={"Delete"} icon={icon} />
    );
  };
  
export const MotivationsShowLink = ({ motivation }) => {
    return (
      <a href={`/motivations/${motivation.id}`}>{motivation.title}</a>
    );
};

const DeletionLink = ({ href, body, icon }) => {
  return (
    <a href={href} className="delete" data-confirm="Are you sure?" rel="nofollow" data-method="delete">
      {
        icon ? <FontAwesomeIcon icon="trash" /> : body
      }
    </a>
  );
};

export default {
    Motivations: {
        Show: MotivationsShowLink
    },
    Goals: {
        Show: GoalsShowLink,
        Edit: GoalsEditLink,
        Delete: GoalsDeleteLink,
    },
}