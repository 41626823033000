import React, { Component } from 'react'
import axios from 'axios'

export default class NoteForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            body: ""
        }

        this.saveNote = this.saveNote.bind(this);
        this.onBodyUpdate = this.onBodyUpdate.bind(this);
    }

    onBodyUpdate(event) {
        const newBody = event.target.value;
        
        this.setState({
            body: newBody
        });
    }

    saveNote(event) {
        event.preventDefault();

        const goal = this.props.goal;
        const authenticityToken = this.props.authenticityToken;

        const body = this.state.body;

        const date = Date.now();

        axios.post(`/goals/${goal.id}/notes`, {
            note: { body, date },
            authenticity_token: authenticityToken
        })
        .then(result => this.onSaveSuccess(result))
        .catch(error => this.onSaveFailure(error));
    }

    onSaveSuccess(result) {
        const note = result.data;

        if (note) {
            // update parent's notes array
            this.props.onNoteAdd(note);
            
            // clear UI
            this.setState({
                body: ""
            });
        }
        // todo: should log this
        else { console.log('empty result onSaveSuccess#NoteForm', note); }
    }

    onSaveFailure(error) {
        console.log("failure", error);

        this.setState({
            body: ""
        });
    }

    render() {
        const goal = this.props.goal;
        const body = this.state.body;
        const disableSubmit = (body.length == 0);

        return(
            <form id="new_note" className="new_note" onSubmit={this.saveNote}>
                <input name="utf8" type="hidden" value="✓" />
                <div className="textarea-container">
                    <textarea ref="bodyInput" id="note_body" placeholder="Write a note..." name="note[body]" value={this.state.body} onChange={this.onBodyUpdate} />
                    <Submit value={"Add Note"} disabled={disableSubmit} />
                </div>
                <input id="note_goal_id" type="hidden" value={goal.id} name="note[goal_id]" />
            </form>
        );
    }
}

const Submit = ({ value, disabled }) => {
    return <input type="submit" name="commit" value={value} data-disable-with={value} disabled={disabled}/>
};