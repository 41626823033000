import React, { Component } from "react";
import axios from 'axios';
import timeAgoInWords from './helpers/timeAgoInWords';
import { FontAwesomeIcon } from './General';

// display Just Now text if the note was recently
const displayJustNow = true;

export default class Note extends Component {
  constructor(props) {
    super(props);

    this.state = {
      note: props.note,
      editMode: false
    };

    this.enterEditMode = this.enterEditMode.bind(this);
    this.deleteNote = this.deleteNote.bind(this);
    this.updateNote = this.updateNote.bind(this);
  }
  
  enterEditMode(event) {
    event.preventDefault();

    this.setState({
      editMode: true
    });
  }

  deleteNote(event) {
    event.preventDefault();

    const note = this.state.note;
    const authenticityToken = this.props.authenticityToken;
    const url = `/goals/${note.goal_id}/notes/${note.id}`;

    axios.delete(
      url,
      { params: { authenticity_token: authenticityToken } }
    )
    .then(result => this.onDeleteSuccess(result.data))
    .catch(error => this.onDeleteFailure(error));
  }

  onDeleteSuccess(id) {
    this.setState({
      note: undefined
    });

    if (id) {
      // update parent's note array
      this.props.onNoteDelete(id);
    }
  }

  onDeleteFailure(error) {
    // handle failure, log the error
    console.log("failure", error);
  }

  toggleEditMode() {
    this.setState({ editMode: false });
  }

  updateNote(changes) {
    // do not save an empty note
    if (changes.body == "") {
      // reset to old state
      // don't make API request to update note's body
      //this.setState({ body: this.props.note.body });
      this.toggleEditMode();
      return;
    }
      
    const note = this.state.note;
    const authenticityToken = this.props.authenticityToken;

    axios.patch(`/goals/${note.goal_id}/notes/${note.id}`, {
      note: { body: changes.body },
      authenticity_token: authenticityToken
    })
    .then(result => this.onUpdateSuccess(result.data));
  }

  onUpdateSuccess(note) {
    this.setState({
      note: note
    });

    this.toggleEditMode();
  }

  displayShowNote() {
    const note = this.state.note;

    return (
      <div className="note">
        <span className="body">
          { note.body }&nbsp;
        </span>
        <span style={{ fontSize: '12px' }}>
          <span>
            {timeAgoInWords(note.date, displayJustNow)}&nbsp;
          </span>
          <span>
            { (note.created_at !== note.updated_at) && "(edited)" }
          </span>
          <span className="links">
            <a href="#" onClick={this.enterEditMode}>
              <FontAwesomeIcon icon="edit" />
            </a>
            <span> </span>
            <a href="#" onClick={this.deleteNote} data-confirm="Are you sure?" rel="nofollow">
              <FontAwesomeIcon icon="trash" />&nbsp;
            </a>
          </span>
        </span>
      </div>
    );
  }

  displayEditNote() {
    const note = this.state.note;

    return (
      <EditNoteForm note={note} updateNote={this.updateNote} />
    );
  }
    
  render() {
    if (!this.state.note) return (<div />);

    if (this.state.editMode) return this.displayEditNote();
    else return this.displayShowNote();
  }
}

export class EditNoteForm extends Component {
  constructor(props) {
    super(props);
    const note = this.props.note;

    this.state = {
      body: note.body
    };

    this.onBodyChange = this.onBodyChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onBodyChange(event) {
    const newBody = event.target.value;

    this.setState({
      body: newBody
    });
  }

  onSave(event) {
    event.preventDefault();
    const body = this.state.body;
    
    this.props.updateNote({ body });
  }

  render() {
    const body = this.state.body;

    return (
      <form className="edit-note">
        <textarea ref="bodyInput" value={body} onChange={this.onBodyChange} />
        <a href="#" 
          onClick={this.onSave}
          className="save"
        >
          Save
        </a>
      </form>
    );
  }
}

// todo: handle time-ago-in-words