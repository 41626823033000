import React, { Component } from 'react';

export default class StripeCheckoutButton extends Component {
    constructor(props) {
        super(props);

        this.redirectToCheckout = this.redirectToCheckout.bind(this);
    }

    redirectToCheckout() {
        const stripe = Stripe(this.props.stripe_publishable_key);

        stripe.redirectToCheckout({
            sessionId: this.props.checkout_session_id
        })
        .then((result) => {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.

            console.log(result.error.message);
        });
    }
    
    render() {
        return (
            <button onClick={this.redirectToCheckout}>I agree. Change my Plan</button>
        );
    }
}