var stripe;

var stripeElements = function(publicKey) {
    stripe = Stripe("HEY");
    var elements = stripe.elements();

    // Element styles
    var style = {
        base: {
            fontSize: '16px',
            color: '#32325d',
            fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: 'rgba(0,0,0,0.4)'
            }
        }
    };

    var card = elements.create('card', { style: style });

    card.mount('#card-element');

    // Element focus ring
    card.on('focus', function() {
        var el = document.getElementById('card-element');
        el.classList.add('focused');
    });

    card.on('blur', function() {
        var el = document.getElementById('card-element');
        el.classList.remove('focused');
    });

    document.querySelector('#submit').addEventListener('click', function(evt) {
        evt.preventDefault();
        chargeLoadingState(true);

        // Initiate payment
        createPaymentMethodAndCustomer(stripe, card);
    });
};

function showCardError(error) {
    changLoadingState(false);

    // The card was declined (i.e. insufficient funds, card has expired, etc)
    var errorMsg = document.querySelector('.sr-field-error');
    errorMsg.textContent = error.message;
    setTimeout(() => {
        errorMsg.textContent = '';
    }, 8000);
}

var createPaymentMethodAndCustomer = function(stripe, card) {
    var cardholderEmail = document.querySelector('#email').nodeValue;
    
    stripe.createPaymentMethod('card', card, {
        billing_details: {
            email: cardholderEmail
        }
    })
    .then(result => {
        if (result.error) {
            showCardError(result.error);
        } else {
            createPaymentMethodAndCustomer(result.paymentMethod.id, cardholderEmail);
        }
    });
};

async function createCustomer(paymentMethod, cardholderEmail) {
    return fetch('/create-customer', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: cardholderEmail,
            payment_method: paymentMethod
        })
    })
    .then(response => {
        return response.json();
    })
    .then(subscription => {
        handleSubscription(subscription);
    })
}

function handleSubscription(subscription) {
    const { latest_invoice } = subscription;
    const { payment_intent } = latest_invoice;

    if (payment_intent) {
        const { client_secret, status } = payment_intent;

        if (status === 'requires_action') {
            stripe.confirmCardPayment(client_secret)
                .then(result => {
                    if (result.error) {
                        // Display error message in your UI
                        // The card was declined (i.e. insufficient funds, card has expired, etc)
                        changeLoadingState(false);
                        showCardError(result.error);
                    } else {
                        // Show a success message to your customer
                        confirmSubscription(subscription.id);
                    }
                });
        } else {
            // No additional information was needed
            // Show a success message to your customer
            orderComplete(subscription);
        }
    } else {
        orderComplete(subscription);
    }
}

function confirmSubscription(subscriptionId) {
    return fetch('/subscription', {
        method: 'post',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({
            subscriptionId: subscriptionId
        })
    })
    .then(response => {
        return response.json();
    })
    .then(subscription => {
        orderComplete(subscription);
    })
}

function getPublicKey() {
    return fetch('/public-key', {
        method: 'get',
        headers: {
            'Content-type': 'application/json'
        }
    })
    .then(response => {
        return response.json();
    })
    .then(response => {
        stripeElements(response.publicKey);
    })
}

getPublicKey();

/*
 * Post payment helpers
 */

/* Shows a success/error message when payment is complete */
const orderComplete = (subscription) => {
  changeLoadingState(false);

  const subscriptionJson = JSON.stringify(subscription, null, 2);

  document.querySelectorAll('.payment-view').forEach((view) => {
    view.classList.add('hidden');
  });

  document.querySelectorAll('.completed-view').forEach((view) => {
      view.classList.remove('hidden');
  })

  document.querySelector('.order-status').textContent = subscription.status;
  document.querySelectorAll('code').textContent = subscriptionJson;
};

// Show a spinner on subscription submission
const changeLoadingState = (isLoading) => {
    if (isLoading) {
        document.querySelector('#spinner').classList.add('loading');
        document.querySelector('button').disabled = true;

        document.querySelector('#button-text').classList.add('hidden');
    } else {
        document.querySelector('button').disabled = false;
        document.querySelector('#spinner').classList.remove('loading');
        document.querySelector('#button-text').classList.remove('hidden');
    }
};