import React, { Component } from 'react'

import ProgressBar from './ProgressBar'
import Note from './Note'
import NoteForm from './NoteForm'
import PageLinks from './PageLinks'
import { FontAwesomeIcon } from './General'

// todo: find a way to pass in entire goal object
// rather than needing to pass notes and motivations
// additionally

export default class Goal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      goal: props.goal,
      notes: props.notes,
      motivations: props.motivations
    };

    this.onNoteAdd = this.onNoteAdd.bind(this);
    this.onNoteDelete = this.onNoteDelete.bind(this);
  }

  // Controls which elements to display at the moment
  // of all of the goal display elements
  conditionalDisplay(elementName, element) {
    const hideList = this.props.hide;
    
    if (hideList.includes(elementName)) return;
  
    return element;
  }

  // update the notes (also updates UI)
  onNoteAdd(newNote) {
    var notes = this.state.notes;
    notes.push(newNote);

    this.setState({
      notes: notes
    });
  }

  onNoteDelete(noteId) {
    const updatedNotes = this.state.notes.filter(note => note.id !== noteId);

    this.setState({
      notes: updatedNotes
    })
  }

  render() {
    const goal = this.state.goal;
    const notes = this.state.notes;
    const motivations = this.state.motivations;

    const authenticityToken = this.props.authenticity_token;

    return (
      <div className="goal">
        { this.conditionalDisplay("title", <Title goal={goal} />) }
        { this.conditionalDisplay("description", <Description goal={goal} />) }
        { this.conditionalDisplay("progress", <Progress goal={goal} />) }
        { this.conditionalDisplay("notes", <Notes notes={notes} authenticityToken={authenticityToken} onNoteDelete={this.onNoteDelete} />) }
        { this.conditionalDisplay("motivations", <Motivations motivations={motivations} />) }
        { this.conditionalDisplay("reward", <Reward goal={goal} />) }
        { this.conditionalDisplay("deadline", <Deadline goal={goal} />) }
        { this.conditionalDisplay("is_current", <IsCurrent goal={goal} />) }
        { this.conditionalDisplay("has_redeemed_award", <HasRedeemedAward goal={goal} />) }
        { this.conditionalDisplay("note_form", <NoteForm goal={goal} authenticityToken={authenticityToken} onNoteAdd={this.onNoteAdd} />) }
        { this.conditionalDisplay("links", <Links goal={goal} />) }
        { this.conditionalDisplay("underline", <hr />) }
      </div>
    );
  }
}

export const Title = ({ goal }) => (
  <div className="title">
    <PageLinks.Goals.Show goal={goal} />
    &nbsp;
    { goal.is_completed && <FontAwesomeIcon icon="check" /> }
  </div>
);

export const Description = ({ goal }) => {
  return (
    <div className="description">
      {goal.description}
    </div>
  );
};

export const Progress = ({ goal }) => (
  <div className="progress">
    <ProgressBar progress={goal.progress} />
  </div>
);

export const Notes = ({ notes, authenticityToken, onNoteDelete }) => {
  if (notes.length == 0) return <div />;
  
  // order notes by ascending date (oldest displayed first)
  notes.sort((a,b) => new Date(a.date) - new Date(b.date));

  return (
    <div className="notes">
      { notes.map((note, index) => <Note note={note} authenticityToken={authenticityToken} onNoteDelete={onNoteDelete} key={index} />) }
    </div>
  );
};

export const Reward = ({ goal }) => {
  const reward = (goal.reward === "") ? "<no reward yet>" : goal.reward
  
  return (
    <div className="reward">
      Reward: {reward}
    </div>
  );
};

export const Deadline = ({ goal }) => {
  const deadline = goal.deadline ? new Date(goal.deadline).toLocaleDateString() : "None";
  
  return (
    <div className="deadline">
      Deadline: {deadline}
    </div>
  );
};

export const MotivationLinks = ({ motivations }) => {
  if (motivations.length <= 0) return "<no motivations selected yet>";

  return (
    motivations.map((motivation, index) => 
        <PageLinks.Motivations.Show motivation={motivation} key={index} />
      ).reduce(
        (prev, curr) => [prev, " | ", curr]
      )
  );
};

export const Motivations = ({ motivations }) => {
  // reduce used to add | divider between
  // motivation links
  return (
    <div className="goal-motivations">
      <span>Motivations: </span>
      <MotivationLinks motivations={motivations} />
    </div>
  );
};

export const IsCurrent = ({ goal }) => {
  if (!goal.is_current) return (<p />);
  
  return (
    <p className="is-current">
      current goal
    </p>
  );
};

export const HasRedeemedAward = ({ goal }) => {
  const has_redeemed_award = goal.has_redeemed_award;
  const is_completed_goal = goal.is_completed;
  
  if (!is_completed_goal) return (<p />);

  const unredeemed_award_text = "Looks like you haven't treated yourself for this achievement yet.";
  const redeemed_award_text = "Awesome that you celebrated this achievement!";

  return (
    <p className="has-redeemed-reward">
      { has_redeemed_award ? redeemed_award_text : unredeemed_award_text }
    </p>
  );
};

export const Links = ({ goal }) => {
  return (
    <React.Fragment>
      <p>
        <span><PageLinks.Goals.Edit goal={goal} /></span>
        <span> | </span>
        <span><PageLinks.Goals.Delete goal={goal} /></span>
      </p>
    </React.Fragment>
  );
};

// todo: use Typescript
// todo: handle validateDOM warning (divs inside p elements)
// todo: do i need to export the private components just for testing?
//       it's what i'm doing right now