/*
    Based on: https://apidock.com/rails/ActionView/Helpers/DateHelper/distance_of_time_in_words
    Source of ruby implementation
 */

 /*
    > 1 year: February 10, 2019
    < 365 days: February 10
    < 60 days & >= 1 month: about X months ago
    < 60 days & <= 1 month: about 1 month ago
    < 30 days: X days ago
    < 1 1/2 days: about 1 day ago
    < 24 hours: about X hours ago
    < 90 minutes: about 1 hour ago
    < 45 minutes: X minutes ago
    < 60 seconds: 1 minute ago
    < 59 seconds: Less than a minute ago
    < 39 seconds: Half a minute ago
    < 19 seconds (don't display just now): Less than X seconds ago
    < 1 second: just now
  */

export const MILLISECONDS_IN_SECONDS = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const MINUTES_IN_DAY = MINUTES_IN_HOUR * 24;
export const MINUTES_IN_MONTH = MINUTES_IN_DAY * 30; // generalization
export const MINUTES_IN_YEAR = MINUTES_IN_DAY * 365; // ignoring leapyear

const MONTH_NAMES = ["January", "February", "March", "April",
    "May", "June", "July", "August", "September", "October",
    "November", "December"];

// todo - write cleaner way of including the TENSE
const TENSE = "ago";

export const timeAgoInWords = (timestamp, displayJustNow) => {
    // in seconds
    const fromDate = new Date(timestamp);
    const toDate = new Date();

    const fromTime = fromDate / MILLISECONDS_IN_SECONDS;
    const toTime = toDate / MILLISECONDS_IN_SECONDS;

    const distanceInSeconds = Math.round((toTime - fromTime));
    const distanceInMinutes = Math.round((toTime - fromTime) / SECONDS_IN_MINUTE);

    if (distanceInMinutes <= 1) {
        // <= 1 second (passed in) - "just now"
        if (displayJustNow) return "Just now";
        // 2 seconds to 19 seconds - "Less than X seconds ago"
        if (distanceInSeconds <= 19) return `less than ${distanceInSeconds} seconds ${TENSE}`;
        // 20 seconds to 39 seconds - "Half a minute ago"
        if (distanceInSeconds <= 39) return `half a minute ${TENSE}`;
        // 40 seconds to 59 seconds - "Less than a minute ago"
        if (distanceInSeconds <= 59) return `less than a minute ${TENSE}`;
        // 60 seconds - "1 minute ago"
        return `1 minute ${TENSE}`;
    }
    
    // more than 1 minutes up to 45 minutes
    // "X minutes ago"
    if (distanceInMinutes < 45) return `${distanceInMinutes} minutes ${TENSE}`
    
    // more than 45 minutes up to 90 minutes
    // "X minutes ago"
    if (distanceInMinutes < 90) return `about 1 hour ${TENSE}`

    // more than 90 minutes up to 24 hours
    // "about X hours ago"
    if (distanceInMinutes < MINUTES_IN_DAY){
        const distanceInHours = Math.round(distanceInMinutes / MINUTES_IN_HOUR)
        return `about ${distanceInHours} hours ${TENSE}`
    }

    // 24 hours up to 36 hours
    // "about 1 day ago"
    if (distanceInMinutes < MINUTES_IN_HOUR * 36) return `about 1 day ${TENSE}`

    // 36 hours up to 30 days
    // "X days ago"
    if (distanceInMinutes < MINUTES_IN_MONTH) {
        const distanceInDays = Math.round(distanceInMinutes / MINUTES_IN_DAY);
        return `${distanceInDays} days ${TENSE}`;
    }
    
    // > 30 days to 60 days
    // "about X month(s) ago"
    if (distanceInMinutes < MINUTES_IN_DAY * 60) {
        const distanceInMonths = Math.round(distanceInMinutes / MINUTES_IN_MONTH);
        if (distanceInMonths <= 1) return `about 1 month ${TENSE}`;
        else  return `about ${distanceInMonths} months ${TENSE}`;
    }
    
    // 60 days up to 365 days
    // e.g. "February 10"
    const monthName = MONTH_NAMES[fromDate.getMonth()];

    if (distanceInMinutes < MINUTES_IN_YEAR) {
        return monthName + " " + fromDate.getDate();
    }
    
    // beyond one year
    // e.g. "February 10, 2019"
    return monthName + " " + fromDate.getDate() + ", " + fromDate.getFullYear();
};

export default timeAgoInWords;